import React, { useState, useEffect, useRef } from "react";
import ProjectSample from "../project_samples/ProjectSamples";
import ProjectSampleMobile from "../project_samples/ProjectSamplesMobile";

import lazuli from '../image/flagship-collection/lazuli-flagship.webp'


import MailchimpForm from "../MailChimpForm";

import lazuliskirt1 from '../image/flirty-lazuli/lazuliskirt1.webp'
import lazulitop1 from '../image/flirty-lazuli/lazulitop1.webp'
import lazulitwopiece from '../image/flirty-lazuli/twopiece1.webp'

import allstar1 from '../image/purses/allstar1.webp'
import allstar2 from '../image/purses/allstar2.webp'
import allstar3 from '../image/purses/allstar3.webp'

import selene1 from '../image/selene-collection/selenebottom.webp'
import selene2 from '../image/selene-collection/seleneshorts.webp'
import selene3 from '../image/selene-collection/selenedress.webp'
import selene4 from '../image/selene-collection/selenetop.webp'
import selene5 from '../image/selene-collection/selenepants.webp'

import universe from '../image/originals/quantum/universe.webp'
import marilyn from '../image/originals/hollywoodicons/marilyn.jpg'
import jordan from '../image/originals/nba/michael.webp'


const Projects = () => {

  // const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  // const intervalRefs = useRef([]); // Array of refs for multiple h2 elements


  const h2Elements = [
    "FLIRTY LAZULI COLLECTION",
    "SELENE PRINCESS COLLECTION",
    "PAINTINGS",
    "PURSES",
  ];
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  


  const dresses = [
    {
      title: "Flirty Lazuli Two Piece Dress",
      imageUrl: lazulitwopiece,
      projectUrl:
        "/FlirtyLazuliTwoPiece",
    },
    {
      title: "Flirty Lazuli Skirt",
      imageUrl: lazuliskirt1,
      projectUrl:
        "/FlirtyLazuliSkirt",
    },
    {
      title: "Flirty Lazuli Tops",
      imageUrl: lazulitop1,
      projectUrl:
        "/FlirtyLazuliTop",
    },
  ];

  const selene = [
    {
      title: "Selene Princess Swimwear High Waisted Reversible Bikini Bottom",
      imageUrl: selene1,
      projectUrl: "/Shop",
    },
    {
      title: "Selene Princess High Waisted Workout Shorts",
      imageUrl: selene2,
      projectUrl: "/SeleneShorts",
    },
    {
      title: "Selene Princess Dress",
      imageUrl: selene3,
      projectUrl: "/SeleneDress",
    },
    // {
    //   title: "Selene Princess revesible Swimwear and Workout Top",
    //   imageUrl: selene4,
    //   projectUrl: "/SeleneBikiniTop",
    // },
    // {
    //   title: "Selene Princess Workout Pants",
    //   imageUrl: selene5,
    //   projectUrl: "/SelenePants",
    // },
  ];

  const purses = [
    {
      title: "",
      imageUrl: allstar1,
      projectUrl: "/Purses",
    },
    {
      title: "All Star Purse",
      imageUrl: allstar2,
      projectUrl: "/Purses",
    },
    {
      title: "",
      imageUrl: allstar3,
      projectUrl: "/Purses",
    },
  ];

  const paintings = [
    {
      title: "Astronaut Series",
      imageUrl: universe,
      projectUrl: "/QuantumCollection",
    },
    {
      title: "Hollywood Icons",
      imageUrl: marilyn,
      projectUrl: "/HollywoodCollection",
    },
    {
      title: "NBA All-Stars",
      imageUrl: jordan,
      projectUrl: "/NBAAllStars",
    },
  ];

  // useEffect(() => {
  //     const section = document.querySelector(".flagship-section");

  //     const observer = new IntersectionObserver((entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           section.classList.add("fade-out");
  //         } else {
  //           section.classList.remove("fade-out");
  //         }
  //       });
  //     });

  //     observer.observe(section);

  //     return () => observer.disconnect();
    
  // }, []);
  
  useEffect(() => {
    // Select all upper-section divs
    const sections = document.querySelectorAll(".upper-section");

    // Create an IntersectionObserver
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = Array.from(sections).indexOf(entry.target); // Get the DOM index
        if (entry.isIntersecting) {
          // Add the appropriate animation class based on the index
          entry.target.classList.add(
            index % 2 === 0 ? "project-slide-in-right" : "project-slide-in-left"
          );
        } else {
          // Remove the animation classes when not visible
          entry.target.classList.remove(
            "project-slide-in-right",
            "project-slide-in-left"
          );
        }
      });
    });

    // Observe each upper-section
    sections.forEach((section) => observer.observe(section));

    // Cleanup function to disconnect the observer
    return () => observer.disconnect();
  }, []);


  
  return (
    <>
      <div className="Content">
        {isMobile ? (
          <>
            <div className="lower-section">
              <div className="upper-section2">
                <section
                  draggable="false"
                  className="flagship-section fade-out"
                >
                  <img
                    src={lazuli}
                    className="flagship-image"
                    alt="lazuli-flagship"
                    draggable="false"
                  ></img>
                </section>
              </div>
            </div>
            <div className="mailchimp">
              <MailchimpForm />
            </div>
            <section className="section-project-description">
              <a
                href="/LazuliCollection"
                className="section-title"
                style={{ width: "40%", justifyContent: "center" }}
              >
                <h2 className="project-title1">FLIRTY LAZULI COLLECTION</h2>
              </a>
            </section>
            <div className="upper-section">
              <ProjectSampleMobile items={dresses} />
            </div>
            <section className="section-project-description">
              <a
                href="/SeleneCollection"
                className="section-title"
                style={{ width: "40%", justifyContent: "center" }}
              >
                <h2 className="project-title1">SELENE PRINCESS COLLECTION</h2>
              </a>
            </section>
            <div className="upper-section">
              <ProjectSampleMobile items={selene} />
            </div>
            <section className="section-project-description">
              <a
                href="/Purses"
                className="section-title"
                style={{ width: "40%", justifyContent: "center" }}
              >
                <h2 className="project-title1">PURSES</h2>
              </a>
            </section>
            <div className="upper-section">
              <ProjectSampleMobile items={purses} />
            </div>
          </>
        ) : (
          <>
            <section className="flagship-section fade-out">
              <img
                src={lazuli}
                className="flagship-image"
                alt="lazuli-flagship"
                draggable="false"
              ></img>
              <div className="mailchimp">
                <MailchimpForm />
              </div>
            </section>
            <div className="scroll-container">
              <div className="upper-section scroll-section">
                <section className="section-project-description">
                  <a
                    href="/LazuliCollection"
                    className="section-title"
                    style={{ width: "40%", justifyContent: "center" }}
                  >
                    <h2 className="project-title3">{h2Elements[0]}</h2>
                  </a>
                </section>
                <ProjectSample items={dresses} />
              </div>
              <div className="upper-section scroll-section">
                <section className="section-project-description">
                  <a
                    href="/SeleneCollection"
                    className="section-title"
                    style={{ width: "40%", justifyContent: "center" }}
                  >
                    <h2 className="project-title3">{h2Elements[1]}</h2>
                  </a>
                </section>
                <ProjectSample items={selene} />
              </div>
              <div className="upper-section scroll-section">
                <section className="section-project-description">
                  <a
                    href="/original-paintings-1"
                    className="section-title"
                    style={{ width: "40%", justifyContent: "center" }}
                  >
                    <h2
                      // ref={h2Ref}
                      href="/LazuliCollection"
                      data-value={h2Elements[1]}
                      className="project-title3"
                    >
                      {h2Elements[2]}
                    </h2>
                  </a>
                </section>
                <ProjectSample items={paintings} />
              </div>
              <div className="upper-section scroll-section">
                <section className="section-project-description">
                  <a
                    href="/Purses"
                    className="section-title"
                    style={{ width: "40%", justifyContent: "center" }}
                  >
                    <h2
                      // ref={h2Ref}
                      href="/LazuliCollection"
                      data-value={h2Elements[1]}
                      className="project-title3"
                    >
                      {h2Elements[3]}
                    </h2>
                  </a>
                </section>
                <ProjectSample items={purses} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Projects;
