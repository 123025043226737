import one from '../../image/originals/abstracts/1.webp' //Fool Them Not Me
import two from '../../image/originals/abstracts/2.webp' //Intentions
import three from '../../image/originals/abstracts/3.webp' //Cancel culture
import four from '../../image/originals/abstracts/4.webp' //Divine Feminine
import five from '../../image/originals/abstracts/5.webp' // It Came In A Dream
import six from '../../image/originals/abstracts/6.webp' // WWW
import reenaRose from '../../image/originals/radical/free.webp'


import Collection from "./Collection2";

const AbstractsCollection = () => {
  const title = "ABSTRACTS";

  const items = [
    {
      title: "Fool Them Not Me",
      imageUrl: one,
      projectUrl: "/FoolThemNotMe",
    },
    {
      title: "Intentions",
      imageUrl: two,
      projectUrl: "/Intentions",
    },
    {
      title: "WWW",
      imageUrl: six,
      available: "out of stock",
      projectUrl: "/WWW",
    },
    {
      title: "Cancel Culture",
      imageUrl: three,
      projectUrl: "/CancelCulture",
    },
    {
      title: "Divine Feminine",
      imageUrl: four,
      available: "out of stock",
      projectUrl: "/DivineFeminine",
    },
    // {
    //   title: "Fool Them Not Me",
    //   imageUrl: one,
    //   projectUrl: "/FoolThemNotMe",
    // },
    {
      title: "It Came In A Dream",
      imageUrl: five,
      available: "out of stock",
      projectUrl: "/ItCameInADream",
    },
    // {
    //   title: "WWW",
    //   imageUrl: six,
    //   available: "out of stock",
    //   projectUrl: "/WWW",
    // },
    {
      title: "Reena Rose",
      imageUrl: reenaRose,
      projectUrl: "/ReenaRose",
    },
  ];

  return <Collection items={items} title={title} backgroundImage={four} />;
};

export default AbstractsCollection;