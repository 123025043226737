import React, { useState, useEffect } from "react";
import ProjectSample from "../../project_samples/ProjectSamples2";
import ProjectSampleMobile from "../../project_samples/ProjectSamplesMobile";

const Collection = ({ items, title, backgroundImage }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // useEffect(() => {
  //   if (!isMobile) {
  //     const section = document.querySelector(".section-project-description2");

  //     const observer = new IntersectionObserver((entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           section.classList.add("fade-out");
  //         } else {
  //           section.classList.remove("fade-out");
  //         }
  //       });
  //     });

  //     observer.observe(section);

  //     return () => observer.disconnect();
  //   }
  // }, [isMobile]);

  return (
    <>
      <div className="Content">
        {isMobile ? (
          <>
            <section className="section-project-description-mobile">
              <h2 className="project-title2">{title}</h2>
            </section>
            <div className="upper-section">
              <ProjectSampleMobile items={items} />
            </div>
          </>
        ) : (
          <>
            <section className="section-project-description2">
              <h2 className="project-title2">{title}</h2>
            </section>
            <div
              className="upper-section"
              style={{
                // backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover", // Keep aspect ratio
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat", // Prevent repeat
                backgroundAttachment: "fixed", // Fix background when scrolling
                backgroundImage: `
      linear-gradient(to top, rgba(255, 255, 255, 1) 24%, rgba(255, 255, 255, 0) 79%),
      url(${backgroundImage})
    `,
              }}
            >
              <ProjectSample items={items} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Collection;
